import React, { useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
// import _ from "lodash"
import Layout from "../components/layout"
import SEO from "../components/seo"

import useInformationDateTitle from "../hooks/use-information-date-title"
import "./css/info.styl"

const Info = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale



  const infoData = useInformationDateTitle().filter(
    info => info.language === language
  )
  // const displayInfoData = _.take(infoData, 5)

  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={language !== "ja" ? "Information" : "お知らせ"}
        keywords={[`Information`, `お知らせ`, `放射線像`, `Autoradiograph`]}
        description={language !== 'ja' ? `Latest information` : `最新情報をお届けいたします。`}
      />
      <section className="information component">
        <h1>Information</h1>
        <ul>
          {infoData.map(info => (
            <Link to={`/info/${info.slug}`} key={info.slug}>
              <li>
                <div className="text">
                  <h3>{info.title}</h3>
                  <p className="date">{info.publishedDate}</p>
                </div>
                <div className="arrow">&#9656; </div>
              </li>
            </Link>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export default Info
